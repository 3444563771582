<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">系统模块</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breadcrumb-active"
          ><i class="fa fa-dashboard"></i>工作台</el-breadcrumb-item
        >
        <el-breadcrumb-item class="breadcrumb-active"
          >组织机构</el-breadcrumb-item
        >
        <el-breadcrumb-item>系统模块</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card-box">
      <!-- 角色列表 -->
      <div class="c-left">
        <el-menu
          class="sidebar-el-menu"
          :default-active="onRoutes"
          :collapse="collapse"
          unique-opened
          @select="select"
          @open="open"
          @close="close"
        >
          <!-- 第一层 -->
          <template v-for="item in navigation">
            <!-- 如果第一层有子菜单，则继续循环 -->
            <template v-if="item.Children">
              <el-submenu :index="`${item.ID}`" :key="`${item.ID}`">
                <template slot="title">
                  <i :class="item.Icon"></i>
                  <span slot="title">{{ item.ModuleName }}</span>
                </template>
                <!-- 第二层 -->
                <template v-for="subItem in item.Children">
                  <!-- 如果第二层有子菜单，则继续循环 -->
                  <template v-if="subItem.Children">
                    <el-submenu :index="`${subItem.ID}`" :key="`${subItem.ID}`">
                      <template slot="title">
                        <span slot="title">{{ subItem.ModuleName }}</span>
                      </template>
                      <!-- <el-menu-item v-for="(threeItem,i) in subItem.subs" :key="i" :index="threeItem.index">{{ threeItem.title }}</el-menu-item> -->
                      <!-- 第三层 -->
                      <template v-for="subItem2 in subItem.Children">
                        <!-- 如果第三层有子菜单，则继续循环 -->
                        <template v-if="subItem2.Children">
                          <el-submenu
                            :index="`${subItem2.ID}`"
                            :key="`${subItem2.ID}`"
                          >
                            <template slot="title">
                              <span slot="title">{{
                                subItem2.ModuleName
                              }}</span>
                            </template>
                            <!-- <el-menu-item v-for="(fourItem,i) in subItem2.subs" :key="i" :index="fourItem.index">{{ fourItem.title }}</el-menu-item> -->
                            <!-- 第四层 -->
                            <template v-for="subItem3 in subItem2.Children">
                              <!-- 如果第四层有子菜单，则继续循环 -->
                              <template v-if="subItem3.Children">
                                <el-submenu
                                  :index="`${subItem3.ID}`"
                                  :key="`${subItem3.ID}`"
                                >
                                  <template slot="title">
                                    <!-- <i :class="item.icon"></i> -->
                                    <!-- <span slot="title">{{ subItem2.title }}</span> -->
                                    {{ subItem3.ModuleName }}
                                  </template>
                                  <el-menu-item
                                    v-for="(fiveItem, i) in subItem3.Children"
                                    :key="i"
                                    :index="`${fiveItem.ID}`"
                                    >{{ fiveItem.ModuleName }}</el-menu-item
                                  >
                                </el-submenu>
                              </template>
                              <!-- 如果第四层没有子菜单 -->
                              <el-menu-item
                                v-else
                                :index="`${subItem3.ID}`"
                                :key="`${subItem3.ID}`"
                                >{{ subItem3.ModuleName }}</el-menu-item
                              >
                            </template>
                          </el-submenu>
                        </template>
                        <!-- 如果第三层没有子菜单 -->
                        <el-menu-item
                          v-else
                          :index="`${subItem2.ID}`"
                          :key="`${subItem2.ID}`"
                          >{{ subItem2.ModuleName }}</el-menu-item
                        >
                      </template>
                    </el-submenu>
                  </template>
                  <!-- 如果第二层没有子菜单 -->
                  <el-menu-item
                    v-else
                    :index="`${subItem.ID}`"
                    :key="`${subItem.ID}`"
                    >{{ subItem.ModuleName }}</el-menu-item
                  >
                </template>
              </el-submenu>
            </template>
            <!-- 如果第一层没有子菜单 -->
            <template v-else>
              <el-menu-item :index="`${item.ID}`" :key="`${item.ID}`">
                <i :class="item.Icon"></i>
                <span slot="title">{{ item.ModuleName }}</span>
              </el-menu-item>
            </template>
          </template>
        </el-menu>
      </div>
      <!-- 菜单权限 -->
      <div class="c-right">
        <el-alert
          title="菜单配置说明"
          type="warning"
          :closable="false"
          show-icon
        >
          <div>
            1、如果是用代码生器生成的Vue页面,Url为Vue项目中src->router->viewGrid.js对应表名的path属性
          </div>
          <div style="padding-top: 5px">
            2、 如果只是建一级菜单或空菜单url不用填写,【视图/表名】填写.或者/
          </div>
        </el-alert>
        <div class="from-boxs">
          <el-form
            :model="limits"
            :rules="rules"
            ref="dataForm"
            class="demo-ruleForm"
          >
            <div class="line-box">
              <el-form-item label="菜单ID" prop="Id">
                <el-input
                  v-model="limits.Id"
                  disabled
                  placeholder="菜单ID"
                ></el-input>
              </el-form-item>
              <el-form-item label="父级ID" prop="ParentId">
                <el-input-number
                  v-model="limits.ParentId"
                  :min="0"
                  :max="100000"
                  label="父级ID"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="菜单名称" prop="NameCHS">
                <el-input
                  v-model="limits.NameCHS"
                  placeholder="菜单名称"
                ></el-input>
              </el-form-item>
            </div>
            <div class="line-box">
              <el-form-item label="(路由)Url" prop="Url">
                <el-input
                  v-model="limits.Url"
                  placeholder="见:上面菜单配置说明"
                ></el-input>
              </el-form-item>
              <el-form-item label="是否启用" prop="Enabled">
                <el-select
                  v-model="limits.Enabled"
                  placeholder="是否启用"
                  filterable
              clearable
                >
                  <el-option
                    v-for="item in isEnabledOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="排序号" prop="OrderId">
                <el-input-number
                  v-model="limits.OrderId"
                  :min="0"
                  :max="10000"
                  label="排序号"
                ></el-input-number>
              </el-form-item>
            </div>
            <div class="line-box">
              <el-form-item label="模块代码" prop="Code">
                <el-input
                  v-model="limits.Code"
                  placeholder="模块代码"
                ></el-input>
              </el-form-item>
              <el-form-item label="模块类型" prop="TypeId">
                <el-select
                  v-model="limits.TypeId"
                  placeholder="模块类型"
                  filterable
              clearable
                >
                  <el-option
                    v-for="item in isTypeIds"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="图标Icon" prop="Icon">
                <div class="icon-box">
                  <i :class="limits.Icon"></i>
                  <el-button
                    type="primary"
                    @click="iocnDialogVisible = true"
                    size="small"
                    plain
                    >选择图标</el-button
                  >
                  <el-button
                    type="danger"
                    size="small"
                    plain
                    @click="limits.Icon = null"
                    >删除图标</el-button
                  >
                </div>
              </el-form-item>
            </div>
            <div class="line-box one-line">
              <div class="name">权限按钮</div>
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="item in checkboxList"
                  :disabled="item.ID===4"
                  :key="item.ID"
                  :label="item.OperationName"
                ></el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="line-box tow-line">
              <el-button
                type="success"
                icon="el-icon-check"
                size="small"
                plain
                @click="selectAll"
                >全选</el-button
              >
            </div>

            <div class="line-box three-line">
              <el-button
                type="primary"
                icon="el-icon-check"
                size="small"
                @click="save"
                >保存</el-button
              >
              <el-button
                type="success"
                @click="newBuilt"
                icon="el-icon-plus"
                size="small"
                >新建</el-button
              >
              <el-button
                type="warning"
                icon="el-icon-plus"
                size="small"
                @click="addChild"
                >添加子级</el-button
              >
              <el-button
                type="primary"
                icon="el-icon-circle-plus"
                size="small"
                plain
                @click="addVisavis"
                >添加同级</el-button
              >
              <el-button
                type="warning"
                icon="el-icon-delete"
                size="small"
                plain
                @click="deleteModuleList"
                >删除菜单</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
    </div>

    <!-- 添加其它权限弹框 -->
    <el-dialog
      title="其它权限"
      :visible.sync="otherDialogVisible"
      width="800px"
      :before-close="handleClose"
      top="13vh"
    >
      <div class="dialog-other-main">
        <el-form :model="others" :rules="otherRules">
          <el-form-item label="权限名称" prop="aName">
            <el-input v-model="others.aName" placeholder="权限名称"></el-input>
          </el-form-item>
          <el-form-item label="权限值" prop="aNumber">
            <el-input v-model="others.aName" placeholder="权限值"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="dialog-other-footer">
        <el-button @click="handleClose" class="pop-close">取消</el-button
        ><el-button class="pop-save" @click="handleClose">保存</el-button>
      </div>
    </el-dialog>

    <!-- 添加图标弹框 -->

    <el-dialog
      title="图标列表"
      :visible.sync="iocnDialogVisible"
      width="800px"
      :before-close="iocnListClose"
      top="13vh"
    >
      <div class="dialog-other-main">
        <div class="iocn-boxs">
          <div
            :class="['iocn-box', { 'active-box': iconActive === item }]"
            @click="changeIocn(item)"
            v-for="item in iocnList"
            :key="item"
          >
            <i :class="['ion', item]"></i>
            <div class="name">{{ item }}</div>
          </div>
        </div>
      </div>
      <div class="dialog-other-footer">
        <el-button class="pop-close" @click="iocnListClose">取消</el-button
        ><el-button class="pop-save" @click="iocnListClose">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getModuleList,
  getModuleListId,
  addModuleListId,
  updateModuleListId,
  deleteModuleListId,
  getButtonsList,
} from "@/api/user";
export default {
  data() {
    return {
      collapse: false,
      navigation: null,
      limits: {
        Id: null,
        Code: "",
        NameCHS: "",
        Description: "",
        TypeId: null,
        Func: "",
        Enabled: null,
        IsKeepAlive: true,
        OrderId: null,
        Url: "",
        ParentId: null,
        Icon: null,
        Operations: [],
      },
      rules: {
        NameCHS: [
          { required: true, message: "菜单名称不能为空", trigger: "blur" },
        ],
        ParentId: [
          { required: true, message: "父级ID不能为空", trigger: "blur" },
        ],
        OrderId: [
          { required: true, message: "排序号不能为空", trigger: "blur" },
        ],
        Enabled: [
          { required: true, message: "请选择是否启用", trigger: "blur" },
        ],
        Code: [
          { required: true, message: "模块代码不能为空", trigger: "blur" },
        ],
        Url: [{ required: true, message: "Url不能为空", trigger: "blur" }],
      },
      isEnabledOptions: [
        {
          value: 1,
          label: "启用",
        },
        {
          value: 2,
          label: "不启用",
        },
      ],
      menuTypeOptions: [
        {
          value: 1,
          label: "pc端菜单",
        },
        {
          value: 2,
          label: "移动端菜单",
        },
      ],
      checkboxList: [],
      isTypeIds: [
        {
          value: 1,
          label: "目录",
        },
        {
          value: 2,
          label: "子菜单",
        },
        {
          value: 3,
          label: "按钮",
        },
      ],
      checkList: [],
      otherDialogVisible: false,
      others: {
        aName: "",
        aNumber: "",
      },
      otherRules: {
        aName: [
          { required: true, message: "权限名称不能为空", trigger: "blur" },
        ],
        aNumber: [
          { required: true, message: "权限值不能为空", trigger: "blur" },
        ],
      },
      iocnDialogVisible: false,
      iocnList: [
        "ion-ionic",
        "ion-arrow-up-a",
        "ion-arrow-right-a",
        "ion-arrow-down-a",
        "ion-arrow-left-a",
        "ion-arrow-up-b",
        "ion-arrow-right-b",
        "ion-arrow-down-b",
        "ion-arrow-left-b",
        "ion-arrow-up-c",
        "ion-arrow-down-c",
        "ion-arrow-left-c",
        "ion-arrow-return-right",
        "ion-arrow-swap",
        "ion-arrow-shrink",
        "ion-arrow-expand",
        "ion-arrow-resize",
        "ion-close-circled",
        "ion-log-out",
        "ion-arrow-move",
      ],
      iconActive: null,
      mduleId: null,
    };
  },
  computed: {
    onRoutes() {
      return this.$route.path.replace("/", "");
    },
  },
  methods: {
    select(index, indexPath) {
      this.mduleId = parseInt(index);
      this.getModuleListId(this.mduleId);
    },
    close(index, indexPath) {
      this.mduleId = parseInt(index);
      this.getModuleListId(this.mduleId);
    },
    open(index, indexPath) {
      this.mduleId = parseInt(index);
      this.getModuleListId(this.mduleId);
    },
    //全选
    selectAll() {
      this.checkList = [];
      this.checkboxList.forEach((item) => {
        this.checkList.push(item.OperationName);
      });
    },
    handleClose() {
      this.otherDialogVisible = false;
    },
    iocnListClose() {
      this.iocnDialogVisible = false;
    },
    changeIocn(icon) {
      this.limits.Icon = "ion " + icon;
      this.iconActive = icon;
    },
    //获取系统菜单
    getModuleList() {
      getModuleList().then((res) => {
        
        if (res.status === 200) {
          this.navigation = res.response;
        }
      });
    },
    //获取每个列表详情
    getModuleListId(id) {
      getModuleListId({ id }).then((res) => {
        
        if (res.status === 200) {
          const data = res.response;
          this.limits = {
            Id: data.ID,
            Code: data.ModuleCode,
            NameCHS: data.ModuleName,
            Description: data.Description,
            TypeId: data.TypeId,
            Func: data.Func,
            Enabled: data.Enable ? 1 : 2,
            IsKeepAlive: true,
            OrderId: data.SortBy,
            Url: data.Url,
            ParentId: data.ParentId,
            Icon: data.Icon,
            OperationIds: [],
          };
          this.checkList = [];
          const OperationIds = data.OperationIds.split(",");
          this.checkboxList.forEach((item) => {
            if (OperationIds.indexOf(`${item.ID}`) !== -1) {
              this.checkList.push(item.OperationName);
            }
          });
          
        }
      });
    },
    //保存新增或编辑
    save() {
      let OperationIds = [];
      this.checkboxList.forEach((item) => {
        if (this.checkList.indexOf(item.OperationName) !== -1) {
          OperationIds.push({ Id: item.ID });
        }
      });
      this.limits.Operations = OperationIds;
      // 编辑
      if (this.limits.Id) {
        updateModuleListId(this.limits).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑保存成功");
            this.getModuleList();
          }
        });
      } else {
        addModuleListId(this.limits).then((res) => {
          if (res.status === 200) {
            this.$message.success("新建保存成功");
            this.getModuleList();
            this.limits = {
              Id: null,
              Code: "",
              NameCHS: "",
              Description: "",
              TypeId: null,
              Func: "",
              Enabled: null,
              IsKeepAlive: true,
              OrderId: null,
              Url: "",
              ParentId: null,
              Icon: null,
            };
          }
        });
      }
    },
    //新建
    newBuilt() {
      this.limits = {
        Id: null,
        Code: null,
        NameCHS: null,
        Description: null,
        TypeId: null,
        Func: null,
        Enabled: null,
        IsKeepAlive: true,
        OrderId: null,
        Url: null,
        ParentId: null,
        Icon: null,
      };
    },
    addChild() {
      if (this.limits.Id) {
        const id = this.limits.Id;
        this.limits = {
          Id: null,
          Code: null,
          NameCHS: null,
          Description: null,
          TypeId: null,
          Func: null,
          Enabled: null,
          IsKeepAlive: true,
          OrderId: null,
          Url: null,
          ParentId: id,
          Icon: null,
        };
      } else {
        this.$message.error("请选择节点");
      }
    },
    addVisavis() {
      if (this.limits.Id) {
        const id = this.limits.ParentId;
        this.limits = {
          Id: null,
          Code: null,
          NameCHS: null,
          Description: null,
          TypeId: null,
          Func: null,
          Enabled: null,
          IsKeepAlive: true,
          OrderId: null,
          Url: null,
          ParentId: id,
          Icon: null,
        };
      } else {
        this.$message.error("请选择节点");
      }
    },
    deleteModuleList() {
      if (!this.limits.Id) {
        this.$message.error("请选择节点");
        return;
      }
      this.$confirm("此操作将永久删除该数据(及其子项), 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteModuleListId({ id: this.mduleId }).then((res) => {
          if (res.status === 200) {
            this.$message.success("删除成功");
            this.getModuleList();
            this.limits = {
              Id: null,
              Code: null,
              NameCHS: null,
              Description: null,
              TypeId: null,
              Func: null,
              Enabled: null,
              IsKeepAlive: true,
              OrderId: null,
              Url: null,
              ParentId: null,
              Icon: null,
            };
          }
        });
      });
    },
    //获取权限列表
    getButtonsList() {
      getButtonsList({
        page: 1,
        size: 1000000,
      }).then((res) => {
        
        if (res.status === 200) {
          this.checkboxList = res.response.data;
        }
      });
    },
  },
  mounted() {
    this.getModuleList();
    this.getButtonsList();
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }
  .card-box {
    width: 100%;
    height: calc(100% - 48px);
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .c-left {
      width: 250px;
      margin-right: 24px;
      background-color: #fff;
      height: 100%;
      flex-shrink: 0;
    }
    .c-right {
      width: 100%;
      background-color: #fff;
      height: 100%;
      .from-boxs {
        width: 100%;
        padding: 24px;
        box-sizing: border-box;
        .line-box {
          display: flex;
          justify-content: flex-start;
          .el-form-item {
            width: 30%;
            display: flex;
            margin-right: 5%;
            flex-direction: column;
            .el-input-number {
              width: 100%;
            }
            .el-select {
              width: 100%;
            }
            .icon-box {
              width: 100%;
              display: flex;
              align-items: center;
              .ion {
                font-size: 24px;
                margin-right: 12px;
                line-height: 40px;
              }
              .fa {
                font-size: 24px;
                margin-right: 12px;
                line-height: 40px;
              }
            }
          }
          .el-form-item:not(3n) {
            margin-right: 0;
          }
        }
        .one-line {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          .name {
            font-size: 14px;
            color: #606266;
            line-height: 40px;
          }
        }
        .tow-line {
          justify-content: flex-start;
          margin-top: 12px;
        }
        .three-line {
          justify-content: center;
          margin-top: 36px;
        }
      }
    }
  }
  .dialog-other-main {
    border-bottom: 2px solid #e6e6e6;
    padding: 12px 24px 0 24px;
    width: 100%;
    box-sizing: border-box;
    .line-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .from-box {
        display: flex;
        align-items: center;
        width: 330px;
        margin-bottom: 24px;
        .name {
          width: 80px;
          font-size: 16px;
          flex-shrink: 0;
        }
        .el-cascader {
          width: 250px;
        }
        .el-input {
          width: 250px;
        }
      }
    }
    .iocn-boxs {
      display: flex;
      flex-wrap: wrap;
      .iocn-box {
        width: 150.4px;
        height: 100px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        .ion {
          font-size: 32px;
          margin-bottom: 12px;
        }
        .name {
          font-size: 14px;
        }
      }
      .active-box {
        .ion {
          color: #409eff;
        }
        .name {
          color: #409eff;
        }
      }
    }
  }
  .dialog-other-footer {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
<style lang="scss" scoped>
</style>
<style scoped>
.el-menu >>> .el-menu-item i {
  color: #303133;
  margin-right: 5px;
  width: 18px;
}
.el-menu >>> .el-menu-item.is-active i {
  color: #409eff;
}

.el-menu >>> .el-submenu__title i {
  color: #303133;
  margin-right: 5px;
  width: 18px;
}
.el-menu >>> .el-submenu__title.is-active i {
  color: #409eff;
}

.el-form-item >>> .el-form-item__label {
  text-align: left;
}
.one-line >>> .el-checkbox {
  margin-bottom: 12px;
}
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 2px solid #e6e6e6;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
</style>
